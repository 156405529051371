<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="gelenteklif"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmam�z Teklifleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>

            <v-dialog v-model="showDetailDialog" class="pb-0 mb-0" width="800" height="600">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col class="pb-0 mb-0" style="height: 600px">
                                <dynoform header="Firmam�z Teklifleri"
                                          @change="UpdateFormField($event, 'dynamicFormData')"
                                          @changeGrid="UpdateExtData($event, 'dynamicFormData')"
                                          @click="FormButtonClicked($event)"
                                          @SearchInputChanged="SearchInputChanged($event, 'dynamicFormData')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"
                                          :tableName="'dynamicFormData'"
                                          :formInfo="DynamicFormInfo"></dynoform>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                               @click="showDetailDialog = false"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize secondary">
                            Kapat
                        </v-btn>
                        <v-btn 
                               @click="UpdateDynamicForm()"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize primary">
                            Kaydet
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>

    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                width: 25
            },
            teslimsuresi:
            {
                type: 'number',
                label: 'FL_DeliveryDuration',
                edit: '01X2',
                name: 'teslimsuresi',
                width: 10
            },
            garanti:
            {
                type: 'number',
                label: 'FL_WarrantyDuration',
                edit: '01X2',
                name: 'garanti',
                width: 10
            },
            talepmiktar:
            {
                type: 'number',
                label: 'FL_AmountRequested',
                edit: '01X2',
                name: 'talepmiktar',
                width: 10,
                validateMin: 1,
            },

            talepbirim: {
                type: 'text',
                label: 'FL_QuantityType',
                edit: '',
                name: 'talepbirim',
                width: 10
            },
            alisfiyat: {
                type: 'number',
                label: 'FL_UnitPriceKDV',
                edit: '01X2',
                name: 'alisfiyat',
                width: 15
            },

            doviz: {
                type: 'select',
                label: 'FL_Currency',
                edit: '01X2',
                name: 'doviz',
                width: 15,
                options: [],
                validateMin: 1,
            },
            button: {
                type: 'button',
                label: 'FL_DetailInformation',
                name: 'button',
                edit: '01X2',
                width: 5,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            taleptutardoviz: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'taleptutardoviz',
                width: 0
            },
            taleptutar: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'taleptutar',
                width: 0
            },
            dovizkur: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'dovizkur',
                width: 0
            },

        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                talepmiktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
                ad: { items: [], error: false, readonly: false, hidden: false },
                doviz: { items: [], error: false, readonly: false, hidden: false },

            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_kalemler'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };


    var teklif_rank = {
        tableName: 'teklif_rank',
        columns: {
            firmaad: {
                type: 'text',
                name: 'firmaad',
                label: 'FL_CompanyTitle',
                edit: '',
                width: 80
            },
            rank:
            {
                type: 'text',
                label: 'FL_Rank',
                edit: '',
                name: 'rank',
                width: 20,
                validateMin: 1,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            }
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_rank'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };


    var form_files = {
        tableName: 'form_files',
        columns: {
            tarih: {
                type: 'date',
                name: 'ad',
                label: 'FL_Date',
                edit: '',
                width: 10
            },
            fieldname:
            {
                type: 'text',
                label: 'FL_FileType',
                edit: '',
                name: 'fieldname',
                width: 10
            },
            url: {
                type: 'button',
                label: 'FL_ShowFile',
                name: 'url',
                edit: '2',
                width: 10
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                talepmiktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
                ad: { items: [], error: false, readonly: false, hidden: false },
                doviz: { items: [], error: false, readonly: false, hidden: false },

            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_files'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var additional_fields = {
        tableName: 'additional_fields',
        columns: {
            Itemid: {
                type: 'hidden',
                name: 'Itemid',
                label: 'Itemid',
                edit: '',
                width: 55
            },
            PropName: {
                type: 'text',
                name: 'PropName',
                label: 'PropName',
                edit: '',
                width: 55
            },
            PropType: {
                type: 'text',
                name: 'PropType',
                label: 'PropType',
                edit: '',
                width: 55
            },
            PropValue: {
                type: 'text',
                name: 'PropValue',
                label: 'PropValue',
                edit: '2',
                width: 55,
                validateMin: 1,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_additional_fields'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var dynamic_formInfo = {
        tabs: [
            { order: 1, header: 'TH_Details' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_Details'
            }
        ],
        Columns: {


        },
        InitialColumns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
        },
        form: 'dynamic_formInfo',
        parameters: [],
        attributes: {}
    };

    var GelenTeklif_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Quatation' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },

            {
                tab: 1,
                order: 2,
                header: 'TH_ProposalConditions'
            },
            {
                tab: 1,
                order: 3,
                header: 'TH_ItemInformation'
            },
            {
                tab: 1,
                order: 4,
                header: 'FL_AdditionalFiles'
            },
            {
                tab: 1,
                order: 5,
                header: 'FL_Notes'
            },
        ],

        Columns: {
            id: {
                type: 'text',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
            ihaleNoText: {
                type: 'text',
                group: 1,
                sira: 14,
                name: 'ihaleNoText',
                label: 'Ihale No',
                edit: '',
                col: '6',
            },
            durum: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },

            sontekliftar:
            {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'sontekliftar',
                label: 'FL_LastResponseDate',
                edit: '',
                col: '6',
            },

            konu: {
                type: 'text',
                group: 1,
                sira: 35,
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            teklifsontarihoncesiacilabilirmi: {
                type: 'select',
                name: 'teklifsontarihoncesiacilabilirmi',
                label: 'FL_teklifsontarihoncesiacilabilirmi',
                edit: '',
                options: [
                ],
                group: 2,
                sira: 20,
                col: '6',
            },

            ihaleyontemi: {
                type: 'select',
                name: 'ihaleyontemi',
                label: 'FL_ihaleyontemi',
                edit: '',
                options: [
                ],
                group: 2,
                sira: 25,
                col: '6',
                hidden: false
            },

            sontarih:
            {
                type: 'date',
                group: 2,
                sira: 30,
                name: 'sontarih',
                label: 'FL_LastDate',
                edit: '',
                col: '3',
            },
            sonsaat:
            {
                type: 'text',
                group: 2,
                sira: 35,
                name: 'sonsaat',
                label: 'FL_LastTime',
                edit: '',
                col: '3',
            },
            teslimatadresi: {
                type: 'textarea',
                group: 2,
                sira: 40,
                name: 'teslimatadresi',
                label: 'Teslimat Adresi',
                edit: '',
                col: '6',
                buttons: [],
                dontCompare: true
            },
            teslimtarihi:
            {
                type: 'date',
                group: 2,
                sira: 45,
                name: 'teslimtarihi',
                label: 'Teslim Tarihi',
                edit: '',
                col: '6',
            },
            teklifRank:
            {
                type: 'text',
                group: 2,
                sira: 49,
                name: 'teklifRank',
                label: 'FL_teklifRank',
                edit: '',
                col: '6',
            },
            teklif_rank: {
                type: 'grid',
                group: 2,
                sira: 55,
                name: 'teklif_rank',
                label: 'TH_TeklifFirmalar',
                edit: '',
                col: '8',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow: false,
                showExportToExcelButton: false,
            },
            sal_kalem_detay: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'sal_kalem_detay',
                label: 'TH_QuatationItems',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow:false,
            },
            aciklamalar: {
                type: 'textarea',
                group: 3,
                sira: 15,
                name: 'aciklamalar',
                label: 'FL_Comment',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true
            },
            administrativeSpecificationFile: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'administrativeSpecificationFile',
                col: '6',
                edit: '',
                label: 'TH_administrativeSpecificationFile'
            },
            technicalSpecificationFile: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'technicalSpecificationFile',
                col: '6',
                edit: '',
                label: 'TH_technicalSpecificationFile'
            },
            tedarikfile: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'tedarikfile',
                col: '6',
                edit: '02',
                label: 'TH_QuatationFileWithPrice',
                validateMin: 1,
            },
            tedarikfileWithoutPrice: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'tedarikfileWithoutPrice',
                col: '6',
                edit: '02',
                label: 'TH_QuatationFileWithoutPrice'
            },
            otherfiles: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'otherfiles',
                col: '6',
                edit: '02',
                label: 'TH_OtherFiles'
            },
            form_files: {
                type: 'grid',
                group: 4,
                sira: 30,
                name: 'form_files',
                label: 'TH_FileHistory',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow: false,
            },
            oonaynot: {
                type: 'history',
                group: 5,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: '01X2',
                label: ''
            },
            teklifisteformno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'teklifisteformno',
                width: 0
            },

        },
        form: 'gelenteklif',
        formid: 0,
        viewName: 'bekleyen',
        viewparameter: '',
        files: ['ek1file', 'tedarikfile', 'tedarikfileWithoutPrice', 'otherfiles', 'administrativeSpecificationFile', 'technicalSpecificationFile'],
        extdata: [sal_kalem_detay, additional_fields, form_files, teklif_rank],
        actions: ['G�nder'],
        parameters: [],
        attributes:{}
    };

    //var detailView_formInfo = {
    //    tabs: [
    //        { order: 1, header: 'FN_DetailView' },

    //    ],
    //    groups: [
    //        {
    //            tab: 1,
    //            order: 1,
    //            header: 'TH_FormInformation'
    //        }
    //    ],

    //    Columns: {
    //        id: {
    //            type: 'hidden',
    //            group: 1,
    //            sira: 10,
    //            name: 'id',
    //            label: 'FL_FormNo',
    //            edit: '',
    //            col: '6',
    //        },
    //        PropValue: {
    //            type: 'text',
    //            group: 1,
    //            sira: 15,
    //            name: 'PropValue',
    //            label: 'FL_Status',
    //            edit: '',
    //            col: '6',
    //        },
    //    },
    //    form: 'gelenteklif',
    //    formid: 0,
    //    viewName: 'tumu',
    //    viewparameter: '',
    //    files: [],
    //    extdata: [sal_kalem_detay, additional_fields],
    //    actions: ['G�nder'],
    //    parameters: [],
    //    attributes: {}
    //};

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    export default {
        name: 'gelenteklif',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true }
        },
        data: function ()
        {
            return {
                valid: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                actionResultDialog: false,
                validateError: '',
                username: '',
                form: '',
                formname: 'gelenteklif',
                openallpanel: [0],
                notification: false,
                currentform: 'gelenteklif',
                errors: [],
                showDetailDialog : false
            };
        },
        methods: {
            FormLoaded: function ()
            {
                var currstate = this.GetFormState;
                this.SetFieldHidden('teklifRank');
                this.SetFieldHidden('teklif_rank');

                if ('X12'.indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    //sontarihvesaat
                    //if ('S'.indexOf(currstate) != -1 && !this.actionBarWait)
                    //{
                    //    this.$store.commit('SetFormActions', [{ label: 'AL_CancelForm', action: 'Iptal', type: 'external' }, { label: 'AL_Complete', action: 'Tamam', type: 'external' }]);
                    //}
                    if (parseInt(this.GetFormField('CanBid')) == 1)
                    {
                        this.$store.commit('SetFormActions', [{ label: 'AL_Send', action: 'Tamam', type: 'external' }]);
                    }
                }
                else
                {
                    this.$store.commit('SetFormActions', []);
                }

                if (this.GetFormField('teklifsontarihoncesiacilabilirmi') == '1') {
                    this.ClearFieldHidden('ihaleyontemi');
                    sal_kalem_detay.columns.doviz.edit = '';
                }
                else {
                    this.SetFieldHidden('ihaleyontemi');
                    sal_kalem_detay.columns.doviz.edit = '01X2';
                }
            },
            ExtDataLoaded: function (table) {
                if (table == 'teklif_rank') {

                    if (this.GetFormField('ihaleyontemi') == '2' || this.GetFormField('ihaleyontemi') == '3') {
                        this.ClearFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');

                        var teklifRank = this.GetExdataItems('teklif_rank');

                        var rank = 0;
                        if (teklifRank.length > 0) {
                            var rankItem = teklifRank.filter(function (item) {
                                return item.own == 1;
                            });
                            if (rankItem.length == 1) {
                                rank = rankItem[0].rank;
                            }

                            var payload = {};
                            payload.teklifRank = rank;

                            this.$store.commit('SetFields', payload);

                            if (this.GetFormField('ihaleyontemi') == '3') {
                                this.ClearFieldHidden('teklif_rank');
                            }
                        }
                        else {
                            this.SetFieldHidden('teklifRank');
                            this.SetFieldHidden('teklif_rank');
                        }
                    }
                    else {
                        this.SetFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');
                    }
                }
            },
            NeedApprove: function (action)
            {
                var res;
                switch (action)
                {
                    case "Tamam":
                        res = '';
                        break;
                    case "Gericek":
                        res = this.$root.$i18n.t('DM_RejectionConfirmation');
                        break;
                    case "Iptal":
                        res = this.$root.$i18n.t('DM_CancelForm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            Selected: function ()
            {
                return;
            },

            GetDetailViewFormView: function () {
                return detailView_formInfo;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
                //if (name == 'ShowRequest') {
                //    //Request formunu g�ster
                //    var url = '/kforms/saltalep/saltalep_frm.aspx?openformid=' + this.GetFormField('RequestId');
                //    window.open(url);
                //}
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload)
            {
                if (payload.button.name == "url") {
                    var openUrl = '/api/showfile?file=' + payload.item.url;
                    window.open(openUrl, '_blank');
                }
                else {
                    this.OpenDynamicForm(payload);
                }
            },

            OpenDynamicForm: function (payload) {
                if (payload != undefined && payload.item != undefined && payload.item.id != undefined) {

                    var exdataItems = this.GetExdataItems('additional_fields');

                    var fields = exdataItems.filter(function (item) {
                        return payload.item.id == item.Itemid;
                    });

                    var setFieldPayload = {};

                    setFieldPayload.form = 'dynamicFormData';
                    setFieldPayload.id = payload.item.id;
                    for (var i = 0; i < fields.length; i++) {
                        var field = fields[i];
                        setFieldPayload[field.id.toString()] = field.PropValue;
                    }
                    this.$store.commit('SetFields', setFieldPayload);

                    dynamic_formInfo.Columns = {};
                    dynamic_formInfo.Columns.id = dynamic_formInfo.InitialColumns.id;
                    for (var i = 0; i < fields.length; i++) {
                        var field = fields[i];
                        var definition =
                        {
                            type: field.PropType,
                            group: 1,
                            sira: 1,
                            name: field.id,
                            label: field.PropName,
                            edit: '012X',
                            col: '6'
                        };
                        dynamic_formInfo.Columns[field.id.toString()] = definition;
                    }

                    this.showDetailDialog = true;
                }
            },
            UpdateDynamicForm: function () {

                var formdata = this.GetFormDataFunction("dynamicFormData");
                var exdataItems = this.GetExdataItems('additional_fields');

                for (var i = 0; i < exdataItems.length; i++) {
                    var field = exdataItems[i];
                    if (field.Itemid == formdata.id) {
                        for (var key in formdata) {
                            if (field.id == key && key != 'form' && key != 'id') {
                                field.PropValue = formdata[key];
                            }
                        }
                    }
                }

                this.showDetailDialog = false;
            },

            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm(action)
            {
                this.ClearFieldErrors();
                var errors = [];
                var result = "";

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;
                var GetExdataColumns = this.GetExdataColumns('sal_kalem_detay');
                var GetExdataItems = this.GetExdataItems('sal_kalem_detay');

                var additionalFieldsExdataColumns = this.GetExdataColumns('additional_fields');
                var additionalFieldsExdataItems = this.GetExdataItems('additional_fields');

                var escapeFields = ['id', 'kformsid'];

                for (var c in columns) {
                    if (formdata[c] == null) formdata[c] = "";

                    var value = formdata[c].toString().trim();
                    var column = columns[c];
                    var attr = attributes[c];
                    var err = '';

                    if (attr.disabled == false) {
                        if (!escapeFields.includes(column.name)) {
                            err = this.ValidateField(value, column);
                        }

                        if (err != '') {
                            errors.push(err);
                            this.SetFieldError(c);
                        }
                    }
                }

                var allZero = true;
                for (var i = 0; i < GetExdataItems.length; i++) {
                    for (var col in GetExdataColumns) {
                        if (GetExdataItems[i][col] == undefined) GetExdataItems[i][col] = "";

                        var value = GetExdataItems[i][col].toString().trim();
                        var column = GetExdataColumns[col];
                        var err = this.ValidateField(value, column);

                        if (col == 'alisfiyat') {
                            if (value != 0) {
                                allZero = false;
                            }
                        }
                        var id = GetExdataItems[i]['rowid'];
                        if (err != '') {
                            errors.push(err);
                            this.SetExtDataFieldError('sal_kalem_detay', id, col);
                        }
                        else {
                            this.ClearExtDataFieldError('sal_kalem_detay', id, col);
                        }
                    }
                }
                if (allZero) {
                    errors.push('En az bir kaleme fiyat girmelisiniz.');
                    this.SetFieldError('sal_kalem_detay');
                }

                for (var i = 0; i < additionalFieldsExdataItems.length; i++) {
                    if (additionalFieldsExdataItems[i]["PropValue"] == undefined) additionalFieldsExdataItems[i]["PropValue"] = "";

                    var value = additionalFieldsExdataItems[i]["PropValue"].toString().trim();
                    var column = additionalFieldsExdataColumns["PropValue"];
                    column.label = additionalFieldsExdataItems[i]["PropName"];
                    column.type = additionalFieldsExdataItems[i]["PropType"];
                    var err = this.ValidateField(value, column);

                    var id = additionalFieldsExdataItems[i]['rowid'];
                    if (err != '') {
                        errors.push("Kalem ek bilgilerindeki " + err);
                        this.SetExtDataFieldError('additional_fields', id, "PropValue");
                    }
                    else {
                        this.ClearExtDataFieldError('additional_fields', id, "PropValue");
                    }
                }

                if (action.action == "Gericek" || action.action == "Iptal")
                {
                    if (!formdata.oonaynot)
                    {
                        var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        errors.push(err);
                        this.SetFieldError("oonaynot");
                    }
                }
                if (errors.length > 0)
                {
                    result = errors;
                }
                return result;
            },
            LoadNewForm() {
                var emptyForm = {};
                emptyForm.state = '0';                

                this.$store.commit("ClearForm");
                this.$store.commit('SetFormData', emptyForm);

                var payload = {};
                payload.teklifisteformno = this.$route.query.teklifId;

                this.$store.commit('SetFields', payload);

                this.$store.dispatch("GetExtData", 'sal_kalem_detay')
                    .then(response => {
                        Utilities.log("Got Extdata", response);
                    })
                    .catch(err => {
                        Utilities.log("Hata:", err);
                    });

                this.$store.dispatch("GetExtData", 'additional_fields')
                    .then(response => {
                        Utilities.log("Got Extdata", response);
                    })
                    .catch(err => {
                        Utilities.log("Hata:", err);
                    });

                payload = {};
                payload.action = "GetTeklifIsteForm"
                payload.data = [{ "formid": this.$route.query.teklifId }];
                this.$store.commit("SetFormLoadingStatus", true);
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        this.$store.commit("SetFormLoadingStatus", false);
                        var formData = response.data.runTimeResult[0];

                        payload = {};
                        payload.aciklamalar = formData.aciklamalar;
                        payload.konu = formData.konu;
                        payload.administrativeSpecificationFile = formData.administrativeSpecificationFile;
                        payload.ihaleyontemi = formData.ihaleyontemi;
                        payload.sonsaat = formData.sonsaat;
                        payload.sontarih = formData.sontarih;
                        payload.technicalSpecificationFile = formData.technicalSpecificationFile;
                        payload.teklifsontarihoncesiacilabilirmi = formData.teklifsontarihoncesiacilabilirmi;
                        payload.teslimatadresi = formData.teslimatadresi;
                        payload.teslimtarihi = formData.teslimtarihi;
                        payload.formkey = formData.formkey;
                        payload.teklifisteformno = this.$route.query.teklifId;
                        payload.durum = this.$root.$i18n.t('FV_Creating');

                        this.$store.commit('SetFields', payload);

                        if (this.GetFormField('teklifsontarihoncesiacilabilirmi') == '1') {
                            this.ClearFieldHidden('ihaleyontemi');
                            sal_kalem_detay.columns.doviz.edit = '';
                        }
                        else {
                            this.SetFieldHidden('ihaleyontemi');
                            sal_kalem_detay.columns.doviz.edit = '01X2';
                        }
                        this.SetFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');

                        this.$store.commit('SetFormActions', [{ label: 'AL_Send', action: 'Tamam', type: 'external' }]);

                    });
            },

            sal_kalem_detay_Row_Changed: function (row) {

                row.rowdata.taleptutardoviz = row.rowdata.alisfiyat * row.rowdata.talepmiktar;
                if (row.rowdata.dovizkur > 0) {
                    row.rowdata.taleptutar = row.rowdata.taleptutardoviz * row.rowdata.dovizkur;
                }
                else {
                    row.rowdata.taleptutar = row.rowdata.taleptutardoviz;
                }

            },
        },
        created: function ()
        {
            sal_kalem_detay.columns.doviz.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: "TL", value: "TL" },
                { text: "USD", value: "USD" },
                { text: "EUR", value: "EUR" },
                { text: "GBP", value: "GBP" }
            ];

            GelenTeklif_formInfo.Columns.teklifsontarihoncesiacilabilirmi.options = [
                { text: this.$root.$i18n.t('AL_OpenTendering'), value: '1' },
                { text: this.$root.$i18n.t('AL_SealedTendering'), value: '0' }
            ];

            GelenTeklif_formInfo.Columns.ihaleyontemi.options = [
                { text: this.$root.$i18n.t('AL_OnlyProcurement'), value: '1' },
                { text: this.$root.$i18n.t('AL_OnlySorting'), value: '2' },
                { text: this.$root.$i18n.t('AL_SortingAndTitle'), value: '3' }
            ];
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', GelenTeklif_formInfo);
            var form = GelenTeklif_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form')
            {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined) {
                    if (Number(params.id) > 0) {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0) {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                    else {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: 0
                        });
                        this.LoadNewForm();
                    }
                }
            }
            else if (whatToShow == 'yeni')
            {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else
            {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo =>
            {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
            DynamicFormInfo: function () {
                return dynamic_formInfo;
            }
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },
            FormAttributes: function (nval, oldval)
            {
                return;
            },
            $route: function (nval, oval)
            {
                console.log('WATCH Route in gelenteklif :', nval, ' form:', this.formname);
                console.log('WATCH Route  in gelenteklif this:', this.$route);
                var form = GelenTeklif_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form')
                {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined)
                    {
                        if (Number(params.id) > 0) {
                            var formid = Number(params.id);

                            if (formid != undefined && formid != 0) {
                                this.$store.commit('SetFormAndFormId', {
                                    form: form,
                                    formid: formid
                                });
                                this.LoadFormData(formid);
                            }
                        }
                        else {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: 0
                            });
                            this.LoadNewForm();
                        }
                    }
                }
                else if (whatToShow == 'yeni')
                {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else
                {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },
            drawer: function (n, o)
            {
                return;
            },

            notification: function (n, o)
            {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            },

            validateError: function (n, o)
            {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            }
        }
    };
</script>
